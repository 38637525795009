<template>
  <div class="main-card card">
    <div class="card-header cyan">
      <h1 class="text-white">Santraller</h1>
      <div class="header-link">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <router-link :to="{ name: 'stations.create' }">
                <v-btn class="mx-2 bg-white" fab>
                  <v-icon class="link-icon">mdi-plus</v-icon>
                </v-btn>
              </router-link>
            </span>
          </template>
          <span> Ekle </span>
        </v-tooltip>
      </div>
    </div>
    <div class="card-body py-4">
      <div class="row">
        <div class="col-12">
          <div class="report-body">
            <div
              class="report-loading card cyan py-3 mx-auto"
              v-if="getStationsTableLoading"
            >
              <h3 class="text-white">
                Santraller Yükleniyor
                <br />
                Lütfen Bekleyiniz
                <i class="fas fa-spinner fa-pulse text-white"></i>
              </h3>
            </div>
            <table
              id="stationsTable"
              class="
                table table-sm table-striped table-bordered table-hover
                w-100
              "
              v-else
            >
              <thead>
                <tr>
                  <th class="text-center text-nowrap">#</th>
                  <th class="text-center text-nowrap">İşlem</th>
                  <th class="text-center text-nowrap">Adı</th>
                  <th class="text-center text-nowrap">Bugünün Üretimi</th>
                  <th class="text-center text-nowrap">Kurulu Güç</th>
                  <th class="text-center text-nowrap">Şehir</th>
                  <th class="text-center text-nowrap">İlçe</th>
                  <th class="text-center text-nowrap">Santral Tipi</th>
                  <th class="text-center text-nowrap">Alan(m2)</th>
                  <th class="text-center text-nowrap">Lisans</th>
                  <th class="text-center text-nowrap">Sil</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in getStations" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td class="text-nowrap text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <router-link
                            :to="{
                              name: 'stations.show',
                              params: { id: item.id },
                            }"
                          >
                            <v-btn class="mx-2 bg-info" fab dark x-small>
                              <v-icon dark>mdi-eye</v-icon>
                            </v-btn>
                          </router-link>
                        </span>
                      </template>
                      <span>Göster</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <router-link
                            :to="{
                              name: 'stations.edit',
                              params: { id: item.id },
                            }"
                          >
                            <v-btn class="mx-2 bg-warning" fab dark x-small>
                              <v-icon dark>mdi-pencil</v-icon>
                            </v-btn>
                          </router-link>
                        </span>
                      </template>
                      <span>Düzenle</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <router-link
                            :to="{
                              name: 'station-auth',
                              params: { id: item.id },
                            }"
                          >
                            <v-btn class="mx-2 bg-primary" fab dark x-small>
                              <v-icon dark>flaticon-users-1</v-icon>
                            </v-btn>
                          </router-link>
                        </span>
                      </template>
                      <span>Yetkilendirme</span>
                    </v-tooltip>
                  </td>
                  <td class="text-nowrap text-right">{{ item.name }}</td>
                  <td
                    class="text-nowrap text-right"
                    :data-order="item.dailyTotal.toFixed(2)"
                  >
                    {{ item.dailyTotalConvert }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{ item.installed_power }}
                  </td>
                  <td class="text-nowrap text-center">{{ item.city }}</td>
                  <td class="text-nowrap text-center">{{ item.county }}</td>
                  <td class="text-nowrap text-right">
                    {{ item.power_station_type_name }}
                  </td>
                  <td class="text-nowrap text-center">{{ item.area }}</td>
                  <td class="text-nowrap text-center">
                    {{ item.license_status }}
                  </td>
                  <td class="text-nowrap text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-btn
                            @click="remove(item.id)"
                            class="mx-2 bg-danger"
                            fab
                            dark
                            x-small
                          >
                            <v-icon dark>mdi-delete</v-icon>
                          </v-btn>
                        </span>
                      </template>
                      <span>Sil</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const EXPORT_COLUMNS = [0, 2, 3, 4, 5, 6, 7, 8, 9]; //Excel&PDF sütunlara göre yazdırma
const FILE_NAME = "Tüm Santraller"; //Excel&PDF dosya ismi

import "@/assets/js/functions/datatables.settings";
import $ from "jquery";

import { mapGetters } from "vuex";
import {
  GET_STATIONS,
  DELETE_STATION,
} from "@/core/services/store/stations.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import Swal from "sweetalert2";

export default {
  name: "stations.index",
  data() {
    return {
      table: $("#stationsTable").DataTable(),
    };
  },
  computed: {
    ...mapGetters([
      "breadcrumbs",
      "pageTitle",
      "getStations",
      "getStationsTableLoading",
    ]),
  },
  methods: {
    remove(id) {
      var self = this;
      Swal.fire({
        text: "Silme İşlemine Devam Etmek İstiyor musunuz?",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
        customClass: {
          confirmButton: "btn font-weight-bold btn-light-success",
          cancelButton: "btn font-weight-bold btn-light-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.$store.dispatch(DELETE_STATION, id).then((res) => {
            self.updateTable();
            Swal.fire({
              text: "Silme İşlemi Başarılı",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Tamam",
              customClass: {
                confirmButton: "btn font-weight-bold btn-light-success",
              },
            });
          });
        }
      });
    },
    updateTable() {
      this.table.destroy();
      this.$store.dispatch(GET_STATIONS).then(() => {
        this.table = $("#stationsTable").DataTable({
          columnDefs: [
            { type: "num", targets: [0, 3] },
            { orderable: false, targets: 1 },
          ],
          buttons: [
            {
              extend: "colvis",
              className: "btn-sm btn-primary mr-1",
              text: "Göster&Gizle",
            },
            /*{
            extend: "pdf",
            className: "btn-sm btn-danger",
            pageSize: "A4",
            title: FILE_NAME,
            filename: FILE_NAME,
            customize: function (doc) {
              doc.pageMargins = [60, 20, 60, 20];
              doc.defaultStyle.fontSize = 14;
              doc.styles.tableHeader.fontSize = 14;
            },
            exportOptions: {
              columns: EXPORT_COLUMNS,
            },
          },*/
            {
              extend: "excel",
              className: "btn-sm btn-danger",
              title: FILE_NAME,
              filename: FILE_NAME,
              exportOptions: {
                columns: EXPORT_COLUMNS,
              },
            },
          ],
        });
      });
    },
  },
  created() {
    this.updateTable();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Santraller", icon: "flaticon-car" },
    ]);
  },
};
</script>
